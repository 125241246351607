import React, { CSSProperties } from 'react';

import { ImageFitMode, generateImageLink } from '../../utils/imgix';

export interface ImageProps {
  url: string | null;
  alt: string;
  width?: number;
  height?: number;
  fitMode?: ImageFitMode;
  sizes: string;
  className?: string;
  style?: CSSProperties;
}

export function Image(props: ImageProps) {
  let { url, alt, sizes, className, style, width = 400, height, fitMode = ImageFitMode.Clip } = props;

  let srcset = React.useMemo(() => {
    return [0.5, 1, 2]
      .map((multiplier) => {
        if (!url) return '';

        let calculatedWidth = width * multiplier;
        let calculatedHeight = height ? height * multiplier : undefined;

        return `${generateImageLink(url, calculatedWidth, calculatedHeight, fitMode)} ${calculatedWidth}w`;
      })
      .join(',');
  }, [url]);

  let fallback = url ? generateImageLink(url, width, height) : '#';

  return fallback ? (
    <img srcSet={srcset} sizes={sizes} src={fallback} alt={alt} title={alt} className={className} style={style} />
  ) : null;
}
