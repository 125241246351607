import React from 'react';

export default function useDelayedShow(delay: number = 100) {
  let [show, setShow] = React.useState(false);

  React.useEffect(() => {
    let timeout = setTimeout(() => {
      setShow(true);
    }, delay);

    return () => {
      try {
        clearTimeout(timeout);
      } catch (e) {
        // do nothing...
      }
    };
  });

  return show;
}
