import React from 'react';

export const GeneralPageHeader: React.FC = () => {
  return (
    <header className="relative h-16 bg-brand-500">
      <div className="absolute z-10 h-full w-full">
        <div className="max-w-screen-xl mx-auto py-2 px-8 h-full flex items-center justify-between text-white">
          <a href="/" className="font-bold text-lg">
            Woyti
          </a>
        </div>
      </div>
    </header>
  );
};
