import React from 'react';

import { DEFAULT_BRAND_COLOR } from '../../constants';
import { ThemeProvider } from '../../contexts/theme-context';
import SEO from '../organisms/seo';
import RouterLoading from './router-loading';

interface Props {
  children: React.ReactNode | React.ReactNodeArray;
  brandColor?: string;
  title?: string;
  description?: string;
  imageUrl?: string;
}

export const PageTemplate: React.FC<Props> = (props) => {
  let { children, brandColor = DEFAULT_BRAND_COLOR, title, description, imageUrl } = props;

  return (
    <ThemeProvider brandColor={brandColor}>
      <RouterLoading>
        <SEO title={title || 'Woyti'} description={description} imageUrl={imageUrl || undefined} />

        {children}
      </RouterLoading>
    </ThemeProvider>
  );
};
