import { useTranslator } from '@translator-app/react';
import classNames from 'classnames';
import { request } from 'graphql-request';
import { GetServerSidePropsContext } from 'next';
import { useRouter } from 'next/router';
import React from 'react';

import { Heading } from '../components/atoms/heading';
import { Image } from '../components/atoms/image';
import { AppBanner } from '../components/organisms/app-banner';
import { ErrorFallback } from '../components/organisms/error-boundary';
import { GeneralPageHeader } from '../components/organisms/general-page-header';
import { PageFooter } from '../components/organisms/page-footer';
import { FallbackPageTemplate } from '../components/templates/fallback-page-template';
import { PageTemplate } from '../components/templates/page-template';
import { GRAPHQL_ENDPOINT, IS_PRODUCTION } from '../constants';
import { WoytiShopPaginatedResultObjectType } from '../generated/graphql';
import { MinimalServerError } from '../types';
import { ShopFragment } from '../utils/api';
import { Sentry, captureException } from './_app';

const PAGE_SIZE = 100;

const ShopsQuery = `
${ShopFragment}

query ListShops($pagination: PaginationInputObjectType, $testmode: Boolean!) {
  getWoytiShops(pagination: $pagination, testmode: $testmode) {
    count
    total
    limit
    skip
    docs {
      ...shopFields
    }
  }
}
`;

const getShopLink = (shop, locale) => {
  return `/${locale}/${shop.organisation ? shop.organisation.pathname : 'null'}/${shop._id}`;
};

interface PageProps {
  shops: WoytiShopPaginatedResultObjectType;
  skip: number;
  error?: MinimalServerError;
}

const PageImpl: React.FC<PageProps> = (props) => {
  let { shops } = props;
  let { translate } = useTranslator();
  let router = useRouter();

  const sortedShops = shops.docs.sort((a, b) => {
    const diff = b.woytiReservationsCount - a.woytiReservationsCount;
    if (diff !== 0) {
      return diff;
    }
    return a.name.localeCompare(b.name);
  });
  return (
    <div className="bg-gray-100 relative">
      <div className="bg-brand-400 pb-36 h-96 overflow-hidden">
        <div
          className="bg-brand-500 transform -rotate-6 h-96 -translate-x-12 -translate-y-64 z-0"
          style={{ width: '150vw' }}
        />

        <div className="absolute top-24 z-10 flex justify-center w-full mb-12">
          <div className="relative max-w-screen-lg mt-8 w-full">
            <div className="w-full md:px-8 lg:pr-36">
              <div className="font-bold text-white text-3xl leading-8 mb-4 px-8">
                {translate('homeScreen.findAWoyti')}
              </div>
            </div>

            <div className="hidden lg:flex absolute z-0" style={{ bottom: -64, right: 16 }}>
              <img src="/assets/illustrations/phone-girl-dark.svg" className="h-48 w-auto" />
              <img src="/assets/illustrations/fridge.svg" className="h-48 w-auto ml-4" />
            </div>
          </div>
        </div>
      </div>

      <div className="max-w-screen-xl mx-auto p-8">
        <Heading>{translate('homeScreen.shops')}</Heading>
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
          {sortedShops.map((shop) => {
            return (
              <a key={shop._id} href={getShopLink(shop, router.locale)} className="block rounded bg-white relative">
                <div
                  className={classNames(
                    'absolute block right-0 z-10 px-4 py-2 rounded-bl rounded-tr text-lg font-medium text-white',
                    {
                      'bg-red-400': shop.woytiReservationsCount < 1,
                      'bg-green-400': shop.woytiReservationsCount > 0,
                    }
                  )}
                >
                  {shop.woytiReservationsCount || '0'}
                </div>

                <div className="h-48 w-full overflow-hidden flex justify-center items-center">
                  {shop.organisation?.logoUrl ? (
                    <Image
                      url={shop.organisation.logoUrl}
                      alt={shop.name}
                      width={350}
                      height={200}
                      sizes="300px"
                      className="h-full w-auto"
                    />
                  ) : (
                    <div className="font-medium text-lg text-gray-600">{shop.name}</div>
                  )}
                </div>

                <div className="p-4">
                  <div className="text-lg font-bold mb-1">{shop.name}</div>
                  <div className="text-gray-600">
                    {shop.address?.street} {shop.address?.streetNumber}, {shop.address?.zipcode} {shop.address?.city},{' '}
                    {shop.address?.country}
                  </div>
                </div>
              </a>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default function Index(props: PageProps) {
  if (props.error) {
    return (
      <FallbackPageTemplate>
        <ErrorFallback />
      </FallbackPageTemplate>
    );
  }

  return (
    <PageTemplate>
      <AppBanner />

      <GeneralPageHeader />

      <PageImpl {...props} />

      <PageFooter />
    </PageTemplate>
  );
}

export async function getServerSideProps(ctx: GetServerSidePropsContext) {
  try {
    let { skip = '0' } = ctx.query;
    let parsedSkip = +(Array.isArray(skip) ? skip[0] : skip) || 0;
    let result = await request<{
      getWoytiShops: WoytiShopPaginatedResultObjectType;
    }>(GRAPHQL_ENDPOINT, ShopsQuery, {
      pagination: {
        skip: parsedSkip,
        limit: parsedSkip + PAGE_SIZE,
      },
      testmode: !IS_PRODUCTION,
    });

    return {
      props: {
        shops: result.getWoytiShops,
      },
    };
  } catch (err) {
    const eventId = captureException(err);
    await Sentry.flush();

    return {
      props: {
        error: {
          message: err.message,
          eventId,
        },
      },
    };
  }
}
