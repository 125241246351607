import Head from 'next/head';
import React from 'react';

import { ImageFitMode, generateImageLink } from '../../utils/imgix';

export type Props = {
  title?: string;
  description?: string;
  imageUrl?: string;
};

export default function SEO(props: Props) {
  let { title = '', description = '', imageUrl = '' } = props;

  const processedImageUrl = React.useMemo(() => {
    if (imageUrl) {
      // Recommended image size by FB and Twitter
      return generateImageLink(imageUrl, 1200, 630, ImageFitMode.Fill);
    } else {
      return null;
    }
  }, [imageUrl]);

  return (
    <Head>
      <link rel="icon" type="image/x-icon" href="/favicon.ico"></link>
      <meta name="viewport" content="width=device-width,minimum-scale=1,initial-scale=1"></meta>

      <title>{title}</title>
      <meta name="title" content={title} />
      <meta name="description" content={description} />

      {/* Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      {!!processedImageUrl && <meta property="og:image" content={processedImageUrl} />}

      {/* Twitter */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
      {!!processedImageUrl && <meta property="twitter:image" content={processedImageUrl} />}
    </Head>
  );
}
