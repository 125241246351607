import React from 'react';

import { GeneralPageHeader } from '../organisms/general-page-header';
import { PageFooter } from '../organisms/page-footer';
import { PageTemplate } from './page-template';

export interface Props {
  children: React.ReactNode;
}

export const FallbackPageTemplate: React.FC<Props> = ({ children }) => {
  return (
    <PageTemplate>
      <GeneralPageHeader />
      <div className="py-16 pb-96">{children}</div>
      <PageFooter />
    </PageTemplate>
  );
};
