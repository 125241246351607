import React from 'react';

import useDelayedShow from '../../hooks/use-delayed-show';
import Spinner from './spinner';

export default function PageSpinner() {
  let showSpinner = useDelayedShow();

  return (
    <div className="w-screen h-screen flex items-center justify-center">{showSpinner && <Spinner size={10} />}</div>
  );
}
