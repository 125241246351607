import { useRouter } from 'next/router';
import React, { FC } from 'react';

import { captureException } from '../../pages/_app';
import PageSpinner from '../atoms/page-spinner';

export type Props = {
  children?: React.ReactNode | React.ReactNodeArray | string;
};

const RouterLoading: FC<Props> = (props: Props) => {
  let { children } = props;
  let router = useRouter();
  let [isLoading, setIsLoading] = React.useState(false);
  let [error, setError] = React.useState<Error | null>(null);

  React.useEffect(() => {
    const routeChangeStartHandler = (url) => {
      console.log('Route change start:', url);
      setIsLoading(true);
      setError(null);
    };

    const routeChangeCompleteHandler = (url) => {
      console.log('Route change completed:', url);
      setIsLoading(false);
      setError(null);
    };

    const routeChangeErrorHandler = (err) => {
      setIsLoading(false);
      setError(err);
      captureException(err);
    };

    router.events.on('routeChangeStart', routeChangeStartHandler);
    router.events.on('routeChangeComplete', routeChangeCompleteHandler);
    router.events.on('routeChangeError', routeChangeErrorHandler);

    return () => {
      router.events.off('routeChangeStart', routeChangeStartHandler);
      router.events.off('routeChangeComplete', routeChangeCompleteHandler);
      router.events.off('routeChangeError', routeChangeErrorHandler);
    };
  }, [router.events, setIsLoading]);

  if (error) {
    return (
      <div
        className="fixed z-50 w-screen h-screen top-0 left-0 flex justify-center items-center"
        style={{ backgroundColor: 'rgba(255, 255, 255, 0.5)' }}
      >
        <div className="text-center">
          <div className="text-lg font-md">Could not load the page {router.asPath}</div>
          <div className="my-2">
            <div>Refresh the page to potentially resolve the issue, if it persists reach out to customer support.</div>
            <div className="mt-2">Error Message: {error.message}</div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <React.Fragment>
      {children}
      {isLoading && (
        <div
          className="fixed z-50 w-screen h-screen top-0 left-0 flex justify-center items-center"
          style={{ backgroundColor: 'rgba(255, 255, 255, 0.5)' }}
        >
          <PageSpinner></PageSpinner>
        </div>
      )}
    </React.Fragment>
  );
};

export default RouterLoading;
