export enum MobileOS {
  IOS,
  Android,
  Other,
}

export function getMobileOS(): MobileOS {
  if (typeof window !== 'undefined') {
    // @ts-ignore
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
      return MobileOS.Android;
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    // @ts-ignore
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return MobileOS.IOS;
    }
  }

  return MobileOS.Other;
}
