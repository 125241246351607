import { useTranslator } from '@translator-app/react';
import Head from 'next/head';
import React, { useEffect, useState } from 'react';

import { ANDROID_APP_ID, IOS_APP_ID } from '../../constants';
import { MobileOS, getMobileOS } from '../../utils/getMobileOS';

interface Props {
  url?: string;
}

export const AppBanner: React.FC<Props> = (props) => {
  const { url } = props;
  const { translate } = useTranslator();
  const [showAndroidPopup, setShowAndroidPopup] = useState(false);

  const iosMetadataContent = [`app-id=${IOS_APP_ID}`];
  if (url) {
    iosMetadataContent.push(`app-argument=${url}`);
  }

  const androidUrl = `http://play.google.com/store/apps/details?id=${ANDROID_APP_ID}`;
  useEffect(() => {
    setShowAndroidPopup(getMobileOS() === MobileOS.Android);
  }, []);

  return (
    <>
      <Head>
        <meta name="apple-itunes-app" content={iosMetadataContent.join(', ')} />
      </Head>

      {showAndroidPopup && (
        <div className="w-full bg-white">
          <div className="flex justify-between items-center p-4 px-8 mx-auto max-w-screen-lg">
            <div className="font-medium">{translate('appBanner.downloadAppText')}</div>
            <a className="bg-blue-600 text-white rounded px-4 py-2 font-medium" href={androidUrl}>
              {translate('appBanner.viewButton')}
            </a>
          </div>
        </div>
      )}
    </>
  );
};
