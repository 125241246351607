import { useTranslator } from '@translator-app/react';
import NextLink from 'next/link';
import React from 'react';

import { SUPPORT_EMAIL } from '../../constants';
import { OrganisationObjectType, ShopObjectType } from '../../generated/graphql';
import MailIcon from '../../icons/mail.svg';
import PhoneIcon from '../../icons/phone.svg';
import { formatPhonenumber } from '../../utils/phonenumber';

export interface PageFooterProps {
  shop?: ShopObjectType;
  organisation?: OrganisationObjectType;
}

export const PageFooter = (props: PageFooterProps) => {
  let { shop, organisation } = props;
  let { translate } = useTranslator();

  let address = shop ? (
    <div>
      <div className="font-medium mb-2">{shop.name}</div>
      <div>
        {shop.address.street} {shop.address.streetNumber}
      </div>
      <div>
        {shop.address.zipcode} {shop.address.city}, {shop.address.country}
      </div>
      <div className="mt-2">
        <a href={`tel:${shop.phonenumber}`} className="flex items-center">
          <PhoneIcon className="w-4 h-4 mr-2" aria-label="call" /> {formatPhonenumber(shop.phonenumber)}
        </a>
      </div>
      <div>
        <a href={`mailto:${shop.email}`} className="flex items-center">
          <MailIcon className="w-4 h-4 mr-2" aria-label="email" /> {shop.email}
        </a>
      </div>
    </div>
  ) : (
    ''
  );

  return (
    <div className="border-t">
      <div className="max-w-screen-xl mx-auto mb-16 px-8">
        <div className="w-full text-gray-500 text-left lg:flex justify-between mt-8 mb-4">
          <div className="lg:flex justify-between">
            <div className="mb-1">{address}</div>
          </div>
        </div>

        <div className="lg:flex flex-wrap font-bold justify-center mb-4">
          <div className="lg:mx-4">
            <a href={`mailto:${shop?.email || SUPPORT_EMAIL}`}>{translate('footer.contactUs')}</a>
          </div>
          <div className="lg:mx-4">
            <NextLink href="/policies/privacy-policy" passHref>
              {translate('footer.privacyPolicy')}
            </NextLink>
          </div>
          <div className="lg:mx-4">
            <NextLink href="/policies/terms-conditions" passHref>
              {translate('footer.termsConditions')}
            </NextLink>
          </div>
          <div className="lg:mx-4">
            <NextLink href="/policies/cookie-policy" passHref>
              {translate('footer.cookiePolicy')}
            </NextLink>
          </div>
        </div>

        <div className="flex flex-wrap justify-between font-medium text-gray-700 my-2">
          {organisation && (
            <div>
              {organisation.companyName} - {organisation.vatNumber}
            </div>
          )}

          <div>&copy; {new Date().getFullYear()} BV Viresmo</div>
        </div>
      </div>
    </div>
  );
};
