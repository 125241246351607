import { stringify } from 'query-string';

export enum ImageFitMode {
  Clip = 'clip', // Default value
  Crop = 'crop',
  Fill = 'fill',
}

export function generateImageLink(url: string, width: number = 400, height?: number, fitMode?: ImageFitMode) {
  let transformations: string[] = [];
  transformations.push(`w-${width}`);

  if (height) {
    transformations.push(`h-${height}`);
  }

  switch (fitMode) {
    case ImageFitMode.Clip:
      transformations.push('c-at_max');
      break;
    case ImageFitMode.Crop:
      transformations.push('c-maintain_ratio');
      break;
    case ImageFitMode.Fill:
      transformations.push('cm-pad_resize');
      transformations.push('bg-FFFFFF');
      break;
    default:
      break;
  }

  return `${url}?${stringify({
    tr: transformations.join(','),
  })}`;
}
